/* You can add global styles to this file, and also import other style files */

::-webkit-scrollbar {
  width: 7px;
} 

::-moz-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 1px;
} 

::-moz-scrollbar-track  {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb{
  background: rgb(30, 0, 255); 
  //border-radius: 3px;
} 

::-moz-scrollbar-thumb {
  background: rgb(30, 0, 255); 
  //border-radius: 3px;
}

.scroller {
  overflow-y: scroll;
  scrollbar-color: #0A4C95 #C2D2E4;
}

/* Handle on hover */
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5ea4f4; 
}

::-moz-scrollbar-thumb:hover {
  background: #5ea4f4; 
}

.c2-circle{
  float: left;
  width: 10px;
  margin-top: 6px;
  margin-right: 10px;
  height: 10px;
  display: block;
  border-radius: 10px;
}


.c2wow-pointer {
  cursor: pointer;
}
.d-hidden {
  opacity: 0;
  height: 0;
}

.note-editing-area, .note-editable{
  background: #e5e4e4;
}
.c2wow-preview-area {
  border: 1px;
  border-style: solid;
  border-color: #bad1fe;
  border-radius: 4px;
  min-height: 80vh;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.logo img {
  width: 130px;
  height: 45px;
}

.logo1 img {
  width: 130px;
  height: 45px;
}

.header-main {
  background: hsl(220, 85%, 61%) !important;
  color: #fff;
}

.right {
  float: right;
}

.left {
  float: right;
}

.page {
  color: black;
  padding: 0 1rem;
  background: #f1f5f9;
  &__box {
    min-height: 40px;
    background: hsl(220, 98%, 75%) !important;
    margin: 0 -1rem;
    padding: 1rem;
    font-size: 16px;
    color:white;
  }
  &__content {
    min-height: calc(100vh - 178px);
    //border-radius: 10px;
    margin-top: 0rem;
    background: #ebebeb16;
    color: black;
    //font-size: 2rem;
    padding: 1rem;
  }
}

table {
  width: 100%;
}

.page-heading-title {
max-width: 120px;
float:left;
min-height: 40px;
text-align: center;
p {
  margin-top: 10px;
}
}


.page-heading-actions {
display: table-cell;
max-width: 600px;
}

.page-heading-actions .mat-mdc-button-base {
margin: 8px 8px 8px 0;
}

.example-spacer {
  flex: 1 1 auto;
}

.c2wow-page-header .mat-expansion-panel-header.mat-expanded {
  height: 12px
}
.mat-mdc-text-field-wrapper {
  background: #fff;
  border-radius: 5px!important;
}

.c2wow-primary-color {
  background: #bad1fe!important;
}


@media (max-width: 768px) {
  .material-icons {
    font-size: 18px;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
      width: 31px;
      height: 27px;
      padding: 7px;
  }
}

 
 
/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';

 @import './assets/css/bootstrap.css';

